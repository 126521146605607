import {Component, OnDestroy, OnInit} from '@angular/core';
import {NumberSliderItemInterface} from 'src/app/components/number-slider/number-slider-item.interface';
import {MeetingDateFilter} from '../date/meeting-date-filter.interface';
import {MeetingRoomSort} from './meeting-room-sort.interface';
import {MeetingService} from '../../../../services/Meetings/MeetingService';
import {MeetBox} from '../../../../components/meetbox/meetbox.interface';
import {DomSanitizer} from '@angular/platform-browser';
import {PropertySelectorType} from '../../../../components/property-selector/property-selector-type.enum';
import {Location} from '@angular/common';
import {NavController} from '@ionic/angular';
import {ToastService} from '../../../../services/General/ToastService';
import Bugsnag from '@bugsnag/js';
import {StorageService} from '../../../../services/Storage/StorageService';
import {FilterService} from '../../../../modals/meet-filter/FilterService';
import {PropertyService} from '../../../../services/Properties/PropertyService';
import {CacheService} from '../../../../services/Cache/CacheService';
import {UserService} from '../../../../services/User/UserService';
import {DateTime} from 'luxon';
import {OfficeService} from '../../../../services/General/OfficeService';


@Component({
  selector: 'app-meet-container',
  templateUrl: './meet-container.component.html',
  styleUrls: ['./meet-container.component.scss'],
})
export class MeetContainerComponent implements OnInit, OnDestroy {
  property: any;
  userProperty: any;
  propertyId: number;
  peopleNumberSliderItems: NumberSliderItemInterface[] = [];
  tvButton = false;
  whiteboardButton = false;
  teamsButton = false;
  dateFilter: MeetingDateFilter;
  sort: MeetingRoomSort = {sortBy: null, sortDirection: null};
  rooms: MeetBox[] = [];
  selectorType = PropertySelectorType.BUTTONSWITCH;
  presetState: any;
  meetingId: number = null;
  subject: string;
  description: string;
  roomSchedules: Array<any>;
  existingEventId: number;
  noRooms = false;
  roomName = null;
  filter: any;
  properties: any;
  capacityFrom = 0;
  timeout = null;
  filterSubscription: any;
  latestSeq = 0;
  previousTokenPrice: number = null;

  constructor(
    private meeting: MeetingService,
    private user: UserService,
    private domSanitizer: DomSanitizer,
    private nav: NavController,
    private location: Location,
    private toaster: ToastService,
    private storage: StorageService,
    private filterService: FilterService,
    private propertyService: PropertyService,
    private cache: CacheService,
    private officeService: OfficeService,
  ) {
  }

  ngOnDestroy() {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    // @ts-ignore
    this.getProperties();
    this.user.isMember(true);

    const state = this.location.getState();
    // @ts-ignore
    if (state?.action) {
      // @ts-ignore
      this.presetState = state;
      // @ts-ignore
      this.meetingId = state.meetingId ? state.meetingId : null;
      // @ts-ignore
      this.subject = state.subject;
      // @ts-ignore
      this.description = state.description ? state.description : null;
      // @ts-ignore
      this.existingEventId = state.eventId ? state.eventId : null;
      // @ts-ignore
      this.roomName = state.roomName ? state.roomName : null;
      // @ts-ignore
      this.propertyId = state.propertyId ? state.propertyId : null;
      // @ts-ignore
      this.previousTokenPrice = state.tokenPrice ? state.tokenPrice : null;
    }

    this.storage.get('meet-previous-capacity-slider-value').then(value => {
      if (value && !(this.presetState && this.presetState.capacity !== null)) {
        setTimeout(() => {
          this.filter = this.filterService.filter;
          this.capacityFrom = value;
          this.filter.next({
            ...this.filter.value,
            capacityFrom: value
          });
        }, 500);
      }
    });

    if (this.officeService.office !== null) {
      this.getOfficeTimes();
    } else {
      this.officeService.onOfficeSet(() => {
        console.log('event triggered');
        this.getOfficeTimes();
      });
    }
  }

  getOfficeTimes() {
    console.log('GET OFFICE TIMES');
    this.officeService.getTimes().then((times: any) => {
      if (times.from && times.till) {
        this.presetState = {
          from: times.from.getTime(),
          till: times.till.getTime(),
          // eslint-disable-next-line max-len
          dateLabel: DateTime.fromJSDate(times.from).toFormat('dd LLL yyyy') + ' ' + DateTime.fromJSDate(times.from).toFormat('HH:mm') + '-' + DateTime.fromJSDate(times.till).toFormat('HH:mm'),
        };
        this.officeService.getLocation().then((location: string) => {
          this.presetState.location = location;
        });
        this.officeService.getSubject().then((subject: string) => {
          this.subject = subject;
        });
        this.dateFilter = {
          date: DateTime.fromMillis(this.presetState.from),
          from: DateTime.fromMillis(this.presetState.from),
          till: DateTime.fromMillis(this.presetState.till),
        };
        console.log('dateFilter', this.dateFilter);
        this.getRooms();
      }

      this.officeService.getAttendees().then((attendees: Array<any>) => {
        this.setAttendeeCountFromOffice(attendees);
      });

      this.officeService.getAttendeeChanges().subscribe((attendees: Array<any>) => {
        this.setAttendeeCountFromOffice(attendees);
      });
    });
  }

  setAttendeeCountFromOffice(attendees: Array<any>) {
    const amount = attendees.length + 1;
    console.log('attendees', amount, attendees);
    let value = 2;
    if (amount > 8) {
      value = 8;
    } else {
      value = amount % 2 === 0 ? amount : amount + 1;
    }
    this.presetState.capacity = value;
    this.filter = this.filterService.filter;
    this.capacityFrom = value;
    this.filter.next({
      ...this.filter.value,
      capacityFrom: value
    });
  }

  getProperties() {
    this.cache.get('personal-properties', this.propertyService.properties()).then(properties => {
      this.properties = properties;
      this.getPropertyFromCache();
    });

    this.user.profile().then(profile => {
      this.userProperty = this.properties.find(prop => parseInt(profile.defaultProperty_id, 10) === prop.id);
    });
  }

  getPropertyFromCache() {
    if (this.propertyId) {
      this.property = this.properties.find(prop => prop.id === this.propertyId);
      if (!this.property) {
        this.property = this.properties[0];
      }
      this.confirmPropertyFilters();
    } else {
      this.storage.get('filter-property').then(result => {
        if (result !== null) {
          this.property = result;
          this.confirmPropertyFilters();
        } else {
          this.user.profile().then(profile => {
            this.property = this.properties.find(prop => parseInt(profile.defaultProperty_id, 10) === prop.id);
            if (!this.property) {
              this.property = this.properties[0];
            }
            this.confirmPropertyFilters();
          });
        }
      });
    }
  }

  confirmPropertyFilters() {
    this.filter = this.filterService.filter;
    this.filter.next({
      ...this.filter.value,
      property: this.property
    });
    this.getFilters();
  }

  getFilters() {
    this.filterSubscription = this.filterService.filter.subscribe(result => {
      this.property = result.property;
      this.propertyId = result.property?.id;
      this.tvButton = result.meetboxHasTV ?? false;
      this.whiteboardButton = result.meetboxHasWhiteboard ?? false;
      this.teamsButton = result.meetboxHasTeams ?? false;
      this.capacityFrom = result.capacityFrom ?? 2;
      this.sort = result.sort ?? {sortBy: null, sortDirection: null};
      this.getRooms();
    });
  }

  searchUpdated() {
    clearTimeout(this.timeout);

    // Make a new timeout set to go off in 1000ms (1 second)
    this.timeout = setTimeout(() => {
      this.getRooms();
    }, 500);
  }

  getRooms(alternative = false) {
    this.noRooms = false;
    this.rooms = [];
    this.latestSeq += 1;
    const seq = this.latestSeq;
    if (this.dateFilter && this.property) {
      this.meeting.availableMeetingRooms(
        this.dateFilter,
        this.capacityFrom,
        this.propertyId,
        this.sort,
        this.tvButton,
        this.whiteboardButton,
        this.teamsButton,
        this.roomName,
        this.presetState ? this.presetState.meetingId : null,
        alternative,
      ).then(rooms => {
        if (seq >= this.latestSeq) {
          if (rooms.length) {
            this.noRooms = alternative;
            this.formatRooms(rooms);
            this.meeting.getScheduledMeetingsForIds(
              this.dateFilter.date.startOf('day'),
              this.dateFilter.date.endOf('day'),
              rooms.map(r => r.id)).then(schedules => {
              this.roomSchedules = schedules;
            });
          } else {
            if (!alternative) {
              this.getRooms(true);
            } else {
              this.noRooms = true;
            }
          }
        }
      }).catch(err => {
        Bugsnag.notify(err);
        this.noRooms = true;
        this.toaster.toast(err.error.errors);
      });
    }
  }

  formatRooms(rooms) {
    this.rooms = rooms.map(room => (this.meeting.formatRoom(room)));
  }

  roomSelected(room: MeetBox) {
    if (this.noRooms && this.rooms.length) {
      this.dateFilter.from = room.from;
      this.dateFilter.till = room.till;
    }
    const date = this.dateFilter.date;
    this.nav.navigateBack(['/meet-confirm', {
      room: JSON.stringify(room),
      info: JSON.stringify({
        from: this.dateFilter.from.set({year: date.year, month: date.month, day: date.day}),
        till: this.dateFilter.till.set({year: date.year, month: date.month, day: date.day}),
        property: this.property,
        subject: this.subject,
        description: this.description,
        previousTokenPrice: this.previousTokenPrice,
      }),
      meetingId: this.meetingId,
      existingEventId: this.existingEventId,
      capacityBooking: false,
    }]);
  }

  dateUpdated(meetingDateFilter: MeetingDateFilter) {
    this.dateFilter = meetingDateFilter;
    this.getRooms();
  }


}
