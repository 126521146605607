/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import {StorageService} from '../Storage/StorageService';
import {AuthService} from '../Auth/AuthService';
import {NavController} from '@ionic/angular';
import {ToastService} from './ToastService';
import Bugsnag from '@bugsnag/js';

@Injectable({
  providedIn: 'root'
})
export class QueryParameterService {
  queryParams: object;

  constructor(private storage: StorageService, private auth: AuthService,
              private nav: NavController, private toaster: ToastService) {
  }

  public clearForUrl(url: string) {
    if (this.queryParams && !['/home', '/login', '/refresh'].includes(url)) {
      this.queryParams = null;
      this.storage.set('received-params', null);
    }
  }

  public checkForParameters(url: string) {
    const urlParamsOnly = url.split('?')[1];
    const params: any = {};
    if (urlParamsOnly) {
      const query = urlParamsOnly.split('&') ?? [];
      query.forEach(item => {
        const itemArr = item.split('=');
        params[itemArr[0]] = itemArr[1];
      });
      this.queryParams = params;
      this.storage.set('received-params', params);
    }
    return params;
  }

  public loginWithTokenParameter(params: any) {
    if (params && params.token) {
      this.auth.tokenLogin(params.token).then(() => {
        this.nav.navigateBack('/home');
      }).catch(error => {
        Bugsnag.notify(error);
        this.toaster.toast('Failed to login with provided token');
        this.storage.set('received-params', null);
      });
      delete params.token;
      this.storage.set('received-params', params);
    }
  }
}
