/* eslint-disable @typescript-eslint/naming-convention */
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {StorageService} from '../Storage/StorageService';
import {AuthService} from '../Auth/AuthService';
import {NavController} from '@ionic/angular';
import {Observable} from 'rxjs';
import Bugsnag from '@bugsnag/js';
import {NetworkService} from '../Network/NetworkService';
import {App} from '@capacitor/app';
import {PusherService} from './PusherService';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  url = null;
  // mdUrl = 'http://hq.local/';
  // mdUrl = 'https://hq.meetdistrict.com/';
  // mdUrl = 'http://localhost:8000/';
  mdUrl = 'https://hqbeta.libaro.be/';
  // dqhUrl = 'https://sodexo.uat-app.digitalhq.com/';
  // dqhUrl = 'https://sodexo.app.digitalhq.com/';
  apiURL: string = null;
  token: string = null;
  tokenSet = false;
  refreshToken: string = null;
  UnauthenticatedCount = 0;
  auth: AuthService;
  network: NetworkService;

  constructor(private http: HttpClient, private storage: StorageService,
              private nav: NavController, private pusher: PusherService) {
    this.pusher.setApiService(this);
    this.setUrl();
    this.init();
  }

  async setUrl() {
    try {
      const info = await App.getInfo();
      if (info.id === 'com.meetdistrict.MeetDistrict') {
        this.url = this.mdUrl;
        this.apiURL = this.url + 'api/';
      } else {
        this.url = await this.storage.get('tenant-url');
        this.apiURL = this.url ? this.url + 'api/' : null;
      }
    } catch (err) {
      this.url = await this.storage.get('tenant-url');
      this.apiURL = this.url ? this.url + 'api/' : null;
    }
    this.pusher.updateKey();
  }

  async checkUrl() {
    if (this.apiURL) {
      return true;
    } else {
      await this.setUrl();
      if (!this.apiURL) {
        if (this.token) {
          this.storage.set('tenant-url', 'https://sodexo.app.digitalhq.com/');
          this.url = 'https://sodexo.app.digitalhq.com/';
          this.apiURL = this.url ? this.url + 'api/' : null;
        }
        throw(new Error('No api URL set'));
      }
      return true;
    }
  }

  init() {
    this.storage.get('token').then(token => {
      this.setToken(token);
    }).catch(error => {
      Bugsnag.notify(error);
    });
    this.storage.get('refreshToken').then(refreshToken => {
      this.refreshToken = refreshToken;
    }).catch(error => {
      Bugsnag.notify(error);
    });
  }

  //Have to set it like this to prevent circular dependency
  setAuthService(service) {
    this.auth = service;
  }

  setNetworkService(service) {
    this.network = service;
  }

  public setToken(token: string, refreshToken: string = null) {
    this.token = token;
    this.tokenSet = true;
    if (refreshToken) {
      this.refreshToken = refreshToken;
    }
  }

  public async get(path: string, params = {}) {
    await this.checkUrl();
    const headers = await this.headers();
    const observable = this.http.get(this.apiURL + path, {headers, params});
    return this.send(observable);
  }

  public async fullPathGet(path: string, params = {}) {
    const headers = await this.headers();
    const observable = this.http.get(path, {headers, params});
    return this.send(observable);
  }

  public async fullPathPost(path: string, params = {}) {
    const headers = await this.headers();
    const observable = this.http.post(path, params, {headers});
    return this.send(observable);
  }

  public async post(path: string, params: object = {}) {
    await this.checkUrl();
    const headers = await this.headers();
    const observable = this.http.post(this.apiURL + path, params, {headers});
    return this.send(observable);
  }

  public async delete(path: string) {
    await this.checkUrl();
    const headers = await this.headers();
    const observable = this.http.delete(this.apiURL + path, {headers});
    return this.send(observable);
  }

  public postWithObservable(path: string, params: object = {}) {
    return new Observable((observer) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      });
      const req = new HttpRequest('POST', this.apiURL + path, params, {
        headers,
        reportProgress: true,
      });

      this.http.request(req)
        .subscribe((event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentComplete = Math.round((event.loaded / event.total) * 100);
            observer.next(percentComplete);
          } else if (event instanceof HttpResponse) {
            observer.next('complete');
            observer.next(event);
          }
        }, error => {
          observer.next('error');
        });
    });
  }

  ping() {
    return this.get('latestAppVersion');
  }

  private async headers() {
    if (this.tokenSet) {
      return {
        ...(this.token && {Authorization: `Bearer ${this.token}`}),
        Accept: 'application/json',
      };
    } else {
      try {
        const token = await this.storage.get('token');
        this.setToken(token);
        return {
          ...(token && {Authorization: `Bearer ${token}`}),
          Accept: 'application/json',
        };
      } catch (err) {
        Bugsnag.notify(err);
      }
    }
  }

  private send(observable) {
    return new Promise<any>((resolve, reject) => {
      observable.subscribe(data => {
        this.network.connected.next(true);
        resolve(data);
      }, error => {
        if (error.status === 0) {
          this.network.connected.next(false);
        }
        if (error.status === 401) {
          this.UnauthenticatedCount++;
          if (this.UnauthenticatedCount === 4) {
            this.tryRefreshToken();
          }
        }
        reject(error);
      });
    });
  }

  private tryRefreshToken() {
    if (this.refreshToken) {
      this.auth.refreshLoginToken(this.refreshToken).then(data => {
        this.storage.set('token', data.access_token);
        this.storage.set('refreshToken', data.refresh_token);
        this.refresh();
      }).catch(err => {
        Bugsnag.notify(err);
        this.bootToLogin();
      });
    } else {
      this.bootToLogin();
    }
  }

  private bootToLogin() {
    this.auth.logout().then(() => {
      this.nav.navigateBack('/');
      this.UnauthenticatedCount = 0;
    });
  }

  private refresh() {
    this.UnauthenticatedCount = 0;
    this.nav.navigateBack(['/refresh'], {skipLocationChange: true, animated: false}).then(() => {
      this.nav.navigateBack(['/home'], {animated: false});
    });
  }
}
