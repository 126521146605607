import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {BookPaymentParameters} from '../../services/Payment/Interfaces/book-payment-parameters.interface';
import {OrderPaymentParameters} from '../../services/Payment/Interfaces/order-payment-parameters.interface';
import {
  SeatReservationPaymentParameters
} from '../../services/Payment/Interfaces/seat-reservation-payment-parameters.interface';
import {PaymentInformation} from '../../services/Payment/Interfaces/payment-information.interface';
import {PaymentService} from '../../services/Payment/PaymentService';
import {Wallet} from '../../pages/wallets/interfaces/wallet.interface';
import {ModalController} from '@ionic/angular';
import {ToastService} from '../../services/General/ToastService';
import Bugsnag from '@bugsnag/js';
import {TenantService} from '../../services/Tenant/TenantService';

@Component({
  selector: 'app-pay',
  templateUrl: './pay-modal.component.html',
  styleUrls: ['./pay-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PayModalComponent implements OnInit {
  @Input() type: string;
  @Input() params: BookPaymentParameters | OrderPaymentParameters | SeatReservationPaymentParameters;
  paymentInformation: PaymentInformation = {price: null, tokens: null, free: false, paymentMethods: []};
  paymentMethods: Array<Wallet>;
  selectedWallet = 0;
  walletType: string = null;
  disableBtn = false;
  tenantName: string;

  constructor(private payment: PaymentService, private modalCtrl: ModalController,
              private toaster: ToastService, private tenantService: TenantService) {
    this.tenantName = tenantService.getName();
  }

  ngOnInit() {
    this.handlePaymentInformation();
  }

  handlePaymentInformation() {
    this.payment.information(this.type, this.params).then(paymentInformation => {
      this.paymentInformation = paymentInformation;
      if (paymentInformation.free) {
        this.modalCtrl.dismiss(paymentInformation.booking ? paymentInformation.booking : null, 'success');
      } else {
        this.paymentMethods = paymentInformation.paymentMethods;
        this.validatePaymentMethods();
      }
    }).catch(err => {
      Bugsnag.notify(err);
      this.toaster.toast(err.error.error);
      this.modalCtrl.dismiss(null, 'failed');
    });
  }

  validatePaymentMethods() {
    let firstValidIndex = 0;
    let validationSet = false;
    this.paymentMethods.forEach((wallet, index) => {
      let valid;
      if (wallet.amount !== null) {
        if (wallet.type === 'customer-token') {
          valid = parseInt(wallet.amount.toString().replaceAll('.', '').replace(',', '.'), 10) >= this.paymentInformation.tokens;
        } else {
          valid = parseFloat(wallet.amount.toString().replaceAll('.', '').replace(',', '.')) >= this.paymentInformation.price;
        }
      } else {
        valid = true;
      }

      if (!valid) {
        wallet.disabled = true;
      }

      if (valid && !validationSet) {
        firstValidIndex = index;
        validationSet = true;
      }

    });
    this.selectedWallet = this.paymentMethods[firstValidIndex].metaData.sequence;
    this.walletType = this.paymentMethods[firstValidIndex].type === 'customer-token' ? 'token' : 'euro';
  }

  selectedWalletChanged(sequenceId) {
    this.selectedWallet = sequenceId;
  }

  walletTypeChanged(type) {
    this.walletType = type;
  }

  isPersonalWallet() {
    const wallet = this.paymentMethods.find(w => w.metaData.sequence === this.selectedWallet);
    if (!wallet) {
      return false;
    } else {
      return wallet.type === 'wallet';
    }
  }

  pay() {
    this.disableBtn = true;
    this.payment.pay(
      this.type, this.params, this.paymentMethods.find(wallet => wallet.metaData.sequence === this.selectedWallet))
      .then(data => {
        this.modalCtrl.dismiss(data, 'success');
      }).finally(() => {
      this.disableBtn = false;
    });
  }

  getSubmitText() {
    switch (this.type) {
      case 'booking':
        return 'Book';
      case 'order':
        return 'Order';
      case 'seatReservation':
        return 'Reserve';
      case 'carParkReservation':
        return 'Reserve';
      default:
        return 'Pay';
    }
  }
}
